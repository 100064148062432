// import image1 from "./../../assets/gallery/drone2cta.jpg";
// import image2 from "./../../assets/gallery/drone1cta.jpg";
// import image3 from "./../../assets/gallery/drone3cta.jpg";
// import bnr1 from "./../../assets/gallery/BNR_front.JPG";
// import bnr2 from "./../../assets/gallery/BNR_side.JPG";
// import bnr3 from "./../../assets/gallery/BNR_side2.jpg";
// import drone3 from "./../../assets/gallery/drone3.jpg";
// import proShow1 from "./../../assets/gallery/proshow1.jpg";
// import proShow2 from "./../../assets/gallery/proshow2.jpg";
// import proShow3 from "./../../assets/gallery/proshow3.jpg";
// import proShow4 from "./../../assets/gallery/proshow4.jpg";
// import proShow5 from "./../../assets/gallery/proshow5.jpg";
// import proShow6 from "./../../assets/gallery/proshow6.JPG";
import magic1 from "./../../assets/gallery/magic.JPG";
// import magic2 from "./../../assets/gallery/magic2.JPG";
// // import Person1 from "./../../assets/gallery/Person1.CR2";



export const imageGallery1 = [
    {
        url: "https://s6.imgcdn.dev/fXcVD.jpg",
        caption: "Majestic Mountains",
    },
    {
        url: "https://s6.imgcdn.dev/fXCsy.jpg",
        caption: "Majestic Mountains",
    },
    {
        url: "https://s6.imgcdn.dev/fXIx8.jpg",
        caption: "Majestic Mountains",
    },
    {
        url: "https://s6.imgcdn.dev/fXNH2.jpg",
        caption: "Majestic Mountains",
    },
    {
        url: "https://s6.imgcdn.dev/fXiIi.jpg",
        caption: "Majestic Mountains",
    },
    {
        url: "https://s6.imgcdn.dev/fXk7H.jpg",
        caption: "Majestic Mountains",
    },
    {
        url: "https://s6.imgcdn.dev/fXnLS.jpg",
        caption: "Majestic Mountains",
    },
    {
        url: "https://s6.imgcdn.dev/fg5eL.jpg",
        caption: "Majestic Mountains",
    },

    // ... add more images
];

export const imageGallery2 = [
    {
        url: "https://s6.imgcdn.dev/fgPca.jpg",
        caption: "Majestic Mountains",
    },
    {
        url: "https://s6.imgcdn.dev/fgUtt.jpg",
        caption: "Majestic Mountains",
    },
    {
        url: "https://s6.imgcdn.dev/fgzYT.jpg",
        caption: "Majestic Mountains",
    },
    {
        url: "https://s6.imgcdn.dev/fgaKD.jpg",
        caption: "Majestic Mountains",
    },
    {
        url: "https://s6.imgcdn.dev/fXcVD.jpg",
        caption: "Majestic Mountains",
    },
    {
        url: "https://s6.imgcdn.dev/fXNH2.jpg",
        caption: "Majestic Mountains",
    },
    {
        url: "https://s6.imgcdn.dev/fXnLS.jpg",
        caption: "Majestic Mountains",
    },
    {
        url: "https://s6.imgcdn.dev/fXIx8.jpg",
        caption: "Majestic Mountains",
    },
    
]
